import { createSlice } from "../../../utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  BookingPackageInterface,
  BookingPackageRoomCategoryListInterface,
  BookingPackageSeasonListInterface,
  BookingState,
  DogTypeEnum,
  PackageAddOnTypeEum,
  PaymentMethodEnum,
  SelectedPackageBookingInterface,
  SiteSettingInterface,
  TIME_SLOT_TYPE_ENUM,
} from "../types";
import { set } from "lodash";
import moment from "moment";
const today: any = moment;
export const initialState: BookingState = {
  ReservationinformationForm: {
    fullName: "",
    address: "",
    email: "",
    tel: "",
    dob: "",
    dateOfVaccination: "",
  },
  tmcForm: { id: "", tmc: false },
  porcessingPayment: false,
  paymnetsecreat: "",

  bookingConfirmationDetails: {
    // picnicFormData: { _id: "", picnic: 0 },
    // carParkingFormDataList: [],
    _id: "",
    bookingUserDetails: {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      mobNumber: "",
      dateOfVaccination: "",
      dob: "",
      address: "",
      mobileNumber: "",
      englishFirstName: "",
      englishLastName: "",
      state: "",
      district: "",
      city: "",
      pincode: "",
      apartment: "",
      dogs: [
        {
          _id: "",
          userId: "",
          dogName: "",
          breedName: "",
          dateOfVaccine: "",
          file: "",
        },
      ],
      carParkingFormDataList: [],
      picnicFormData: { _id: "", picnic: "" },
      carParkingFormData: {
        _id: "",
        arrivalTime: new Date(today),
        carPersonEmail: "",
        carPersonFirstName: "",
        carPersonLastName: "",
        carPersonmobileNumber: "",
        sameAsUser: false,
        carPersonEnglishFirstName: "",
        carPersonEnglishLastName: "",
        description: "",
      },
      //new data
      picnic: 0,
      arrivalTime: "",
      carPersonEmail: "",
      carPersonFirstName: "",
      carPersonLastName: "",
      carPersonmobileNumber: "",
      sameAsUser: false,
      carPersonEnglishFirstName: "",
      carPersonEnglishLastName: "",
      description: "",
      picnicCharges: 0,
    },
    bookingPackageDetailsList: [],
    bookingPackageDetailsFromList: {
      extraPeople: 0,
      extraChild: 0,
      extraSmallDogs: 0,
      extraBigAndMediumDogs: 0,
      extraDogs: 0,
      maxNoOfDogs: 0,
      dogExtraCharges: 0,
      _id: "",
      title: "",
      roomCategory: "",
      packageBasePrice: 0,
      date: "",
      cartId: "",
      bbq: 0,
      bigAndMediumDogs: 0,
      child: 0,
      createdAt: "",
      endTime: "",
      helpText: "",
      inventory: 0,
      maxBigDogs: 0,
      maxChild: 0,
      maxMediumDogs: 0,
      maxPeople: 0,
      maxSmallDogs: 0,
      noOfBbq: 0,
      noOfShoes: 0,
      packageID: "",
      people: 0,
      price: 0,
      quantity: 0,
      shoes: 0,
      smallDogs: 0,
      startTime: "",
      bbqEnable: false,
      shoesEnable: false,
      bigAndMediumDogsEnable: false,
      smallDogsEnable: false,
      userShoesEnable: false,
      dogsEnable: false,
      dogs: 0,
      timeSlot: TIME_SLOT_TYPE_ENUM.SINGLE,
      addOns: [],
      addOPtionForm: {
        _id: "",
        packageId: "",
        title: "",
        price: 0,
        type: PackageAddOnTypeEum.PACKAGE_BASED,
        addOnId: "",
        addOnPeople: 0,
        minPeople: 0,
        maxPeople: 0,
        createdAt: "",
        updatedAt: "",
        noOfAddOnPerDay: 0,
        image: "",
        description: "",
      },
      cartAddOn: [],
      dogType: DogTypeEnum.BOTH,
      maxBigAndMediumDogs: 0,
      pplExtraCharges: 0,
      childrenExtraCharges: 0,
      bigAndMediumDogExtraCharges: 0,
      smallDogExtraCharges: 0,
    },
    totalPrice: "",
    picnicAvailable: false,
    finalAmount: "",
    tax: "",
    picnicCharges: "",
  },

  packageTotalPriceDetails: { _id: "" },
  loading: false,
  bookingpackageList: [],
  packageListFrom: {
    // dogs: 0,
    packageList: [],
    people: 1,
    child: 0,
    bigAndMediumDogs: 0,
    smallDogs: 0,
  },
  date: null,
  createdOnFilter: 0,
  searchFrom: { people: 1, child: 0, bigAndMediumDogs: 0, smallDogs: 0 },
  dateArray: {
    startDate: moment().format("YYYY/MM/DD"),
    endDate: moment().format("YYYY/MM/DD"),
  },
  BookingSeasonList: [],
  BookingRoomCategoryList: [],
  siteSetting: {
    _id: "",
    picnicPrice: 0,
    minPicnicPeople: 0,
    maxPicnicPeople: 0,
  },
  pivateKey: "",
  paymentMethodForm: { id: "", paymentMethod: PaymentMethodEnum.All },
  merchantId: "",
  publicKeyId: "",
  sandbox: false,
  signature: "",
  payload: {},
  showPaymentProceed: false,
};

export const useClinicSlice = createSlice({
  name: "bookingState",
  initialState,
  reducers: {
    setporcessingPayment: (state, action: PayloadAction<boolean>) => {
      state.porcessingPayment = action.payload;
    },
    setpaymnetsecreat: (state, action: PayloadAction<string>) => {
      state.paymnetsecreat = action.payload;
    },
    setPivateKey: (state, action: PayloadAction<string>) => {
      state.pivateKey = action.payload;
    },
    setAmazoneData: (state, action: PayloadAction<any>) => {
      state.payload = action.payload;
    },
    setAmazoneDataMerchantId: (state, action: PayloadAction<string>) => {
      state.merchantId = action.payload;
    },
    setAmazoneDataPublicKeyId: (state, action: PayloadAction<string>) => {
      state.publicKeyId = action.payload;
    },
    setAmazoneDataSandbox: (state, action: PayloadAction<string>) => {
      state.sandbox = JSON.parse(action.payload);
    },
    setAmazoneDataSignature: (state, action: PayloadAction<string>) => {
      state.signature = action.payload;
    },
    // merchantId: string;
    // publicKeyId: string;
    // sandbox: boolean;
    // signature: string;
    setPaymentMethod: (state, action: PayloadAction<number>) => {
      state.paymentMethodForm.paymentMethod = action.payload;
    },
    getSecratKey: (state) => {},

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    doBookingPackageList: (state) => {
      state.loading = true;
    },
    doBookingPackageSeasomTimeListList: (state) => {},
    doBookingPackageDetailsList: (state) => {},
    setBookingPackageList: (
      state,
      action: PayloadAction<Array<BookingPackageInterface>>
    ) => {
      state.loading = false;
      state.bookingpackageList = action.payload;
    },
    setBookingSeasonList: (
      state,
      action: PayloadAction<Array<BookingPackageSeasonListInterface>>
    ) => {
      state.BookingSeasonList = action.payload;
    },
    setBookingRoomCategoryList: (
      state,
      action: PayloadAction<Array<BookingPackageRoomCategoryListInterface>>
    ) => {
      state.BookingRoomCategoryList = action.payload;
    },
    selectDateForPackageBooking: (
      state,
      action: PayloadAction<{
        index: number;
        packageID: string;
        date: string;
        title: string;
        timeSlot: number;
        startTime: string;
        endTime: string;
        timeSlotIndex: number;
      }>
    ) => {
      const ind = state.packageListFrom.packageList.findIndex(
        (x) =>
          x.packageID == action.payload.packageID &&
          x.date == action.payload.date &&
          x.title == action.payload.title &&
          x.timeSlot == action.payload.timeSlot &&
          x.startTime == action.payload.startTime &&
          x.endTime == action.payload.endTime &&
          x.timeSlotIndex == action.payload.timeSlotIndex
      );

      if (ind !== -1) {
        state.packageListFrom.packageList.splice(ind, 1);
      } else {
        state.packageListFrom.packageList.push({
          packageID: action.payload.packageID,
          date: action.payload.date,
          title: action.payload.title,
          timeSlot: action.payload.timeSlot,
          startTime: action.payload.startTime,
          endTime: action.payload.endTime,
          timeSlotIndex: action.payload.timeSlotIndex,
        });
      }
    },
    SpliceselectDateForPackageBooking: (
      state,
      action: PayloadAction<{
        index: number;
        packageID: string;
        date: string;
        title: string;
        timeSlot: number;
        startTime: string;
        endTime: string;
        timeSlotIndex: number;
      }>
    ) => {
      state.packageListFrom.packageList.splice(action.payload.index, 1);
    },
    clearSelectedPackageFormFrom: (state) => {
      state.packageListFrom.packageList = [];
      state.packageListFrom.people = 1;
      state.packageListFrom.child = 0;
      state.packageListFrom.bigAndMediumDogs = 0;
      state.packageListFrom.smallDogs = 0;
    },
    clearSelectedSearchPackageFormFrom: (state) => {
      state.searchFrom.people = 1;
      state.searchFrom.child = 1;
      state.searchFrom.bigAndMediumDogs = 1;
      state.searchFrom.smallDogs = 1;
    },
    clearSelectedDatePackageFormFrom: (state) => {
      state.dateArray.startDate = "";
      state.dateArray.endDate = "";
    },
    updateDateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `dateArray.${action.payload.key}`, action.payload.value);
    },
    doAddBookingPackageList: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {},
    updateBookingSearchFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `packageListFrom.${action.payload.key}`, action.payload.value);
    },
    setBookingPackageDetailsList: (
      state,
      action: PayloadAction<Array<SelectedPackageBookingInterface>>
    ) => {
      state.bookingConfirmationDetails.bookingPackageDetailsList =
        action.payload;
    },
    setUserDetailsForPackage: (state, action: PayloadAction<any>) => {
      state.bookingConfirmationDetails.bookingUserDetails = action.payload;
    },
    setTotalPriceOfPackage: (state, action: PayloadAction<any>) => {
      state.packageTotalPriceDetails = action.payload;
    },
    setTotalDataOfBookingPackage: (state, action: PayloadAction<any>) => {
      state.bookingConfirmationDetails._id =
        state.bookingConfirmationDetails._id;
      state.bookingConfirmationDetails.totalPrice = action.payload.totalPrice;
      state.bookingConfirmationDetails.picnicAvailable =
        action.payload.picnicAvailable;
      state.bookingConfirmationDetails.finalAmount = action.payload.finalAmount;
      state.bookingConfirmationDetails.tax = action.payload.tax;

      state.bookingConfirmationDetails.bookingUserDetails = action.payload.user;
      state.bookingConfirmationDetails.bookingPackageDetailsList =
        action.payload.package;
      state.bookingConfirmationDetails.bookingPackageDetailsFromList = {
        _id: state.bookingConfirmationDetails.bookingPackageDetailsFromList._id,
        cartId:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.cartId,
        title:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.title,
        roomCategory:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .roomCategory,
        packageBasePrice:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .packageBasePrice,
        date: state.bookingConfirmationDetails.bookingPackageDetailsFromList
          .date,
        dogs: state.bookingConfirmationDetails.bookingPackageDetailsFromList
          .dogs,
        dogsEnable:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .dogsEnable,
        bbq: state.bookingConfirmationDetails.bookingPackageDetailsFromList.bbq,
        bigAndMediumDogsEnable:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .bigAndMediumDogsEnable,
        smallDogsEnable:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .smallDogsEnable,
        bigAndMediumDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .bigAndMediumDogs,
        userShoesEnable:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .userShoesEnable,
        child:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.child,
        createdAt:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .createdAt,
        endTime:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .endTime,
        helpText:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .helpText,
        inventory:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .inventory,
        maxBigDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxBigDogs,
        maxChild:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxChild,
        maxMediumDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxMediumDogs,
        maxPeople:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxPeople,
        maxSmallDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxSmallDogs,
        noOfBbq:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .noOfBbq,
        noOfShoes:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .noOfShoes,
        packageID:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .packageID,
        people:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.people,
        price:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.price,
        shoesEnable:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .shoesEnable,
        bbqEnable:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .bbqEnable,
        quantity:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .quantity,
        shoes:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.shoes,
        smallDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .smallDogs,
        startTime:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .startTime,
        timeSlot: TIME_SLOT_TYPE_ENUM.SINGLE,
        addOns:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList.addOns,
        addOPtionForm:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .addOPtionForm,
        cartAddOn:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .cartAddOn,
        dogType:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .dogType,
        maxBigAndMediumDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxBigAndMediumDogs,
        pplExtraCharges:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .pplExtraCharges,
        childrenExtraCharges:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .childrenExtraCharges,
        bigAndMediumDogExtraCharges:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .bigAndMediumDogExtraCharges,
        smallDogExtraCharges:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .smallDogExtraCharges,
        dogExtraCharges:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .dogExtraCharges,
        maxNoOfDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .maxNoOfDogs,
        extraPeople:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .extraPeople,
        extraChild:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .extraChild,
        extraSmallDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .extraSmallDogs,
        extraBigAndMediumDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .extraBigAndMediumDogs,
        extraDogs:
          state.bookingConfirmationDetails.bookingPackageDetailsFromList
            .extraDogs,
      };
    },
    updateBookingConfirmationFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any; index: any }>
    ) => {
      set(
        state,
        `bookingConfirmationDetails.bookingPackageDetailsList[${action.payload.index}].${action.payload.key}`,
        action.payload.value
      );
    },
    updateBookingConfirmationAddOptionFormValue: (
      state,
      action: PayloadAction<{
        key: string;
        value: any;
        index: any;
        addOnid: string;
      }>
    ) => {
      set(
        state,
        `bookingConfirmationDetails.bookingPackageDetailsList[${action.payload.index}].${action.payload.key}`,
        action.payload.value
      );
    },
    updateBookingCarParkingFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `bookingConfirmationDetails.bookingUserDetails.${action.payload.key}`,
        action.payload.value
      );
    },
    updateBookingPicnicFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `bookingConfirmationDetails.bookingUserDetails.${action.payload.key}`,
        action.payload.value
      );
    },
    doUpdateBookingData: (
      state,
      action: PayloadAction<{ callback: () => void; index: number }>
    ) => {},
    setDate: (state, action: PayloadAction<any>) => {
      state.date = action.payload;
    },
    updateCratedFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `createdOnFilter`, action.payload.value);
    },
    updateBookingFilterFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `searchFrom.${action.payload.key}`, action.payload.value);
    },
    updateBookingTmcFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `tmcForm.${action.payload.key}`, action.payload.value);
    },
    doGetPicnicSetting: (state) => {},
    setSiteSettingData: (
      state,
      action: PayloadAction<SiteSettingInterface>
    ) => {
      state.siteSetting = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(
        state,
        `paymentMethodForm.${action.payload.key}`,
        action.payload.value
      );
    },
    clearFrom: (state) => {
      state.paymentMethodForm.id = "";
      state.paymentMethodForm.paymentMethod = PaymentMethodEnum.All;
    },

    resetSearchFrom: (state) => {
      state.searchFrom = initialState.searchFrom;
      state.dateArray = initialState.dateArray;
    },
    resetPaymentFrom: (state) => {
      state.porcessingPayment = false;
      state.bookingConfirmationDetails =
        initialState.bookingConfirmationDetails;
      state.tmcForm = initialState.tmcForm;
      state.paymentMethodForm = initialState.paymentMethodForm;
    },
    setShowPaymentProceed: (state, action: PayloadAction<boolean>) => {
      state.showPaymentProceed = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
