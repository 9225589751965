import axios from "axios";
import Api from "./Api";
const LoginRequest = async (data: any) => {
  return Api.post(`public/user/login`, data);
};

const MeProfileRequest = async () => {
  return Api.get(`/auth/user/me`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const SaveStoryRequest = async (data: any) => {
  return Api.post(`story/userstory/add`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateStoryRequest = async (data: any) => {
  return Api.patch(`story/userstory/edit`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const GetStorylistRequest = async () => {
  return Api.post(
    `story/userstory/getall/1/200`,
    {},
    { headers: { Authorization: "Bearer " + localStorage.getItem("token") } }
  );
};

const GetStoryEditRequest = async (id: string) => {
  return Api.get(`story/get/user/story/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getStateRequest = async (pinCode: any) => {
  return Api.get(`/country/getCountries/${pinCode}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getUserDataRequestAPI = async () => {
  return Api.get(`auth/user/my/profile`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getSecratKeyAPI = async (filter:any) => {
  return Api.get(`/booking/proceed/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getLoginRequestAPI = async () => {
  return Api.get(`/country/getCountries`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const getPasswordRequestAPI = async () => {
  return Api.get(`/country/getCountries`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UserDetailUpdateRequest = async (data: any) => {
  return Api.patch(`auth/user/profile/update`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};

const UserSignUpRequest = async (data: any) => {
  return Api.post(`public/user/sign-up`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UserUpdateRequest = async (data: any) => {
  return Api.post(`public/user/sign-up`, data);
};
const ForgetPasswordRequest = async (data: any) => {
  return Api.post(`public/user/forgot-password`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdatePasswordRequest = async (data: any) => {
  return Api.patch(`auth/user/change-password`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const UpdateLoginIdRequest = async (data: any) => {
  return Api.patch(`auth/user/update-email`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doCencleBooking = async (id: any) => {
  return Api.patch(
    `booking/user/cancel-booking${id}`,
    {},
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const getImageRequest = async (data: any) => {
  return Api.post(
    `auth/user/singed-url`,
    { fileName: data },
    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const uplodeImageRequest = async (signedUrl: any, data: any) => {
  return axios.put(`${signedUrl}`, data, {
    headers: {
      "Content-Type": "fileType",
    },
  });
};
//******* get Booking Package List *****/
const doGetBookingPackage = async (data: any) => {
  return Api.get(`/admin/package/available/package/list${data}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doAddPackageList = async (data: any) => {
  return Api.post(`availability/getPackageDetails`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const dodeleteDogDetail = async (id: any) => {
  return Api.delete(`/admin/delete/dog/${id}`,  {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetBookingPackageDetails = async () => {
  return Api.get(`/usercart/get`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doUpdateBookingdata = async (data: any) => {
  return Api.put(
    `/usercart/update`,
    {
      _id: data._id,
      cartId: data.cartId,
      people: data.people,
      userShoesEnable: data.userShoesEnable,
      dogs: data.dogs,
      noOfBbq: data.noOfBbq,
      noOfShoes: data.noOfShoes,
      child: data.child,
      sameAsUser: data.sameAsUser,
      carPersonFirstName: data.carPersonFirstName,
      carPersonLastName: data.carPersonLastName,
      carPersonEnglishFirstName: data.carPersonEnglishFirstName,
      carPersonEnglishLastName: data.carPersonEnglishLastName,
      carPersonEmail: data.carPersonEmail,
      carPersonmobileNumber: data.carPersonmobileNumber,
      arrivalTime: data.arrivalTime,
      description: data.description,
      picnic: data.picnic,
      addOnId: data.addOnId,
      addOnPeople: data.addOnPeople,
      smallDogs: data.smallDogs,
      bigAndMediumDogs: data.bigAndMediumDogs,
    },

    {
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    }
  );
};
const doAddContactUs = async (data: any) => {
  return Api.post(`public/user/contact-us`, data, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const EmailVerifiedIndexDetail = async (data: any) => {
  return Api.get(`/public/user/verify-email${data}`);
};
const doGetBookingPackageSeasonList = async (data: any) => {
  return Api.get(`/admin/package/season/timeslot${data}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetuserBookingDetails = async (filter: any) => {
  return Api.get(`/usercart/booking/${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetuserLatestBookingDetails = async () => {
  return Api.get(`/usercart/latest-booking`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetFeaturedGalleryList = async () => {
  return Api.get(`/gallery/featured/photo/list`);
};
const doGetGalleryList = async (filter: any) => {
  return Api.get(`/gallery/all/photo/list/${filter}`);
};
const doGeTopicList = async (filter: any) => {
  return Api.get(`/news/list/${filter}`);
};
const doGetTopicForNHomePageList = async () => {
  return Api.get(`/news/latest/list`);
};
const doGetCategoryList = async () => {
  return Api.get(`/news/category/all/list`);
};
const doGetNewsById = async (id: string) => {
  return Api.get(`/news/get/${id}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
const doGetSiteSettingList = async (filter: string) => {
  return Api.get(`/setting/get${filter}`, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
};
export {
  getLoginRequestAPI,
  getPasswordRequestAPI,
  getUserDataRequestAPI,
  UserUpdateRequest,
  getImageRequest,
  uplodeImageRequest,
  UpdateLoginIdRequest,
  UpdatePasswordRequest,
  ForgetPasswordRequest,
  UserSignUpRequest,
  LoginRequest,
  MeProfileRequest,
  SaveStoryRequest,
  GetStorylistRequest,
  GetStoryEditRequest,
  UpdateStoryRequest,
  getStateRequest,
  getSecratKeyAPI,
  doAddPackageList,
  doGetBookingPackage,
  doGetBookingPackageDetails,
  UserDetailUpdateRequest,
  doUpdateBookingdata,
  doAddContactUs,
  EmailVerifiedIndexDetail,
  doGetBookingPackageSeasonList,
  doGetuserBookingDetails,
  doGetuserLatestBookingDetails,
  doGetFeaturedGalleryList,
  doGetGalleryList,
  doCencleBooking,
  doGetTopicForNHomePageList,
  doGeTopicList,
  doGetCategoryList,
  doGetNewsById,
  doGetSiteSettingList,
  dodeleteDogDetail,
};
