import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";
import { RootState } from "../../../types";

const selectDomain = (state: RootState) => {
  if (state) {
    return state.bookingState;
  } else {
    return initialState;
  }
};

export const selectPorcessingPayment = createSelector(
  [selectDomain],
  (state) => state.porcessingPayment
);

export const selectPaymnetsecreat = createSelector(
  [selectDomain],
  (state) => state.paymnetsecreat
);
export const selectPivateKey = createSelector(
  [selectDomain],
  (state) => state.pivateKey
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectBookingPackage = createSelector(
  [selectDomain],
  (state) => state.bookingpackageList
);

export const selectBookingPackageAddFormList = createSelector(
  [selectDomain],
  (state) => state.packageListFrom
);
export const selectBookingPackageAddList = createSelector(
  [selectDomain],
  (state) => state.packageListFrom.packageList
);
export const selectBookingPackageDetailsList = createSelector(
  [selectDomain],
  (state) => state.bookingConfirmationDetails.bookingPackageDetailsList
);
export const selectBookingUserDetails = createSelector(
  [selectDomain],
  (state) => state.bookingConfirmationDetails.bookingUserDetails
);
export const selectPackageTotalPriceDetails = createSelector(
  [selectDomain],
  (state) => state.packageTotalPriceDetails
);
export const selectBookingConfirmationDetails = createSelector(
  [selectDomain],
  (state) => state.bookingConfirmationDetails
);
export const selectBookingConfirmationFormDataDetails = createSelector(
  [selectDomain],
  (state) => state.bookingConfirmationDetails.bookingPackageDetailsFromList
);
export const selectBookingCarParkingFormData = createSelector(
  [selectDomain],
  (state) =>
    state.bookingConfirmationDetails.bookingUserDetails.carParkingFormData
);
export const selectBookingPicnicFormData = createSelector(
  [selectDomain],
  (state) => state.bookingConfirmationDetails.bookingUserDetails.picnicFormData
);
export const SelectDateFilter = createSelector(
  [selectDomain],
  (state) => state.date
);
export const SelectCreatedOnFilter = createSelector(
  [selectDomain],
  (state) => state.createdOnFilter
);
export const SelectBookingSearchFrom = createSelector(
  [selectDomain],
  (state) => state.searchFrom
);
export const SelectdateArray = createSelector(
  [selectDomain],
  (state) => state.dateArray
);
export const SelectBookingSeasonList = createSelector(
  [selectDomain],
  (state) => state.BookingSeasonList
);
export const SelectAddOPtionForm = createSelector(
  [selectDomain],
  (state) =>
    state.bookingConfirmationDetails.bookingPackageDetailsFromList.addOPtionForm
);
export const SelectTmcForm = createSelector(
  [selectDomain],
  (state) => state.tmcForm
);
export const SelectBookingRoomCategoryList = createSelector(
  [selectDomain],
  (state) => state.BookingRoomCategoryList
);
export const SelectSiteSetting = createSelector(
  [selectDomain],
  (state) => state.siteSetting
);
export const SelectPaymentMethodForm = createSelector(
  [selectDomain],
  (state) => state.paymentMethodForm
);
export const SelectAmazonePayDataPayload = createSelector(
  [selectDomain],
  (state) => state.payload
);
export const SelectAmazonePayDataMerchantId = createSelector(
  [selectDomain],
  (state) => state.merchantId
);
export const SelectAmazonePayDataPublicKeyId = createSelector(
  [selectDomain],
  (state) => state.publicKeyId
);
export const SelectAmazonePayDataSandbox = createSelector(
  [selectDomain],
  (state) => state.sandbox
);
export const SelectAmazonePayDataSignature = createSelector(
  [selectDomain],
  (state) => state.signature
);
export const SelectShowPaymentProceed = createSelector(
  [selectDomain],
  (state) => state.showPaymentProceed
);
