import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";
import { RootState } from "../../../types/RootState";

const selectDomain = (state: RootState) => {
  console.log("state.authState", state);
  if (state && state.myPageState) {
    return state.myPageState;
  } else {
    return initialState;
  }
};
export const selectChnageCurrentPasswordForm = createSelector(
  [selectDomain],
  (state) => state.chnageCurrentPassword
);
export const selectChnageLoginIdForm = createSelector(
  [selectDomain],
  (state) => state.chnageLoginId
);
export const selectUserBookinglist = createSelector(
  [selectDomain],
  (state) => state.userBookinglist
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const SelectPageNo = createSelector(
  [selectDomain],
  (state) => state.pageNo
);
export const SelectPageSize = createSelector(
  [selectDomain],
  (state) => state.pageSize
);
export const selectTotalRow = createSelector(
  [selectDomain],
  (state) => state.totalRow
);
export const selectUserLatestBookinglist = createSelector(
  [selectDomain],
  (state) => state.userLatestBookinglist
);
export const SelectHasMore = createSelector(
  [selectDomain],
  (state) => state.hasMore
);
export const SelectShowModel = createSelector(
  [selectDomain],
  (state) => state.showModel
);